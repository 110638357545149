import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import RiskCircles from '@components/ColumnInserts/RiskCircles/RiskCircles'
import React from 'react'
import { getStatusColor } from '@components/CommonSC/General'
import { TeamInterface } from '@src/interfaces/teams'

export const teamGenericNameColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.team,
  title: 'Team',
}

export const circlesKriColumn: ColumnInterface<{}> = {
  type: CellTypes.insert,
  idPoint: 'kris_above_level_1',
  dataPoint: 'kris_above_level_1',
  sortKey: 'total_kris_above_level',
  filterKey: null,
  insert: ({ data }) => {
    return <RiskCircles data={data} />
  },
  selectorsKey: selectorKeys.none,
  title: 'Risk',
}

export const teamNameColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'team.id',
  dataPoint: 'team.name',
  sortKey: 'team__name',
  filterKey: 'team__id',
  selectorsKey: selectorKeys.team,
  title: 'Team',
}

export const teamDepartmentColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'team.department.id',
  dataPoint: 'team.department.name',
  sortKey: 'team__department__name',
  filterKey: 'team__department__id',
  selectorsKey: selectorKeys.department,
  title: 'Department',
}

export const teamStatusColumn: ColumnInterface<TeamInterface> = {
  type: CellTypes.text,
  idPoint: 'approval_status',
  dataPoint: 'approval_status',
  sortKey: 'approval_status',
  filterKey: 'approval_status',
  selectorsKey: selectorKeys.teams_approval_status,
  title: 'Status',
  colors: data => getStatusColor(data.approval_status),
}
