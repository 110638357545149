import { Flex, AvatarSkeleton, TextSkeleton } from '@revolut/ui-kit'
import React from 'react'

export const HeaderSummarySkeleton = () => (
  <Flex style={{ flexGrow: 1 }} alignItems="center" justifyContent="space-between">
    <Flex gap="s-6" alignItems="center">
      <AvatarSkeleton size={76} />
      <Flex gap="s-4" flexDirection="column">
        <TextSkeleton size={16} variant="emphasis1" />
        <TextSkeleton size={14} variant="body2" />
      </Flex>
    </Flex>

    <Flex gap="s-6" alignItems="center">
      <AvatarSkeleton size={40} />
      <Flex gap="s-4" flexDirection="column">
        <TextSkeleton size={16} variant="emphasis1" />
        <TextSkeleton size={14} variant="body2" />
      </Flex>
    </Flex>

    <Flex gap="s-6" alignItems="center">
      <AvatarSkeleton size={40} />
      <Flex gap="s-4" flexDirection="column">
        <TextSkeleton size={16} variant="emphasis1" />
        <TextSkeleton size={14} variant="body2" />
      </Flex>
    </Flex>

    <Flex gap="s-6" alignItems="center">
      <AvatarSkeleton size={40} />
      <Flex gap="s-4" flexDirection="column">
        <TextSkeleton size={16} variant="emphasis1" />
        <TextSkeleton size={14} variant="body2" />
      </Flex>
    </Flex>
  </Flex>
)
