import React, { useEffect, useMemo } from 'react'
import { Box, InputGroup, Text, Token } from '@revolut/ui-kit'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { selectorKeys } from '@src/constants/api'
import TimezoneSelect from '@src/pages/Forms/Candidate/ScheduleSidebar/TimezoneSelect'
import { ScheduleSidebarModeType } from '@src/interfaces/interviewTool'
import {
  CustomDate,
  getTimeZoneId,
  isBeforeCurrent,
} from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SendCandidateEmailInterface } from '@src/interfaces/hiringProccess'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import LapeSingleCheckbox from '@components/Inputs/LapeFields/LapeSingleCheckbox'
import { EditDateTime } from '@src/pages/Forms/Candidate/ScheduleSidebar/EditDateTime'
import { useScheduleInterviewContext } from '@src/pages/Forms/Candidate/ScheduleInterview/ScheduleInterviewContext'
import { useGetEligibleInterviewers } from '@src/api/recruitment/interviewerScheduling'
import { useGetSelectors } from '@src/api/selectors'
import { transformOptionToEmployee } from '@src/utils/employees'
import { RadioSelectOption } from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { pickRandomValueFromArray } from '@src/utils/numbers'

type Props = {
  mode: ScheduleSidebarModeType
  roundId?: number
}

const EditManualDate = ({ mode, roundId }: Props) => {
  const { values } = useLapeContext<SendCandidateEmailInterface>()
  const { setInterviewer, setAdditionalInterviewers, setCalendarEvent, setTimeZone } =
    useScheduleInterviewContext()

  const timeZoneId = getTimeZoneId(values.scheduling_timezone)
  const { data: eligibleReviewersData, isLoading: eligibleReviewersLoading } =
    useGetEligibleInterviewers(values.interview_stage?.id, roundId)
  const employeeSelector = useGetSelectors(selectorKeys.employee)
  const notEligibleReviewers = useMemo(
    () =>
      employeeSelector.data
        ?.filter(
          employee =>
            !eligibleReviewersData?.find(
              eligibleReviewer => eligibleReviewer.id === employee.id,
            ),
        )
        .map(item => ({
          value: transformOptionToEmployee(item),
          label: item.name,
        })) || [],
    [employeeSelector, eligibleReviewersData],
  )
  const eligibleReviewers = useMemo(
    () =>
      eligibleReviewersData?.map(item => ({
        value: transformOptionToEmployee(item),
        label: item.name,
      })) || [],
    [eligibleReviewersData],
  )

  const leadInterviewerOptions = useMemo(() => {
    const additionalInterviewerIds = new Set(
      values.additional_interviewers?.map(item => item.id) || [],
    )
    return [...eligibleReviewers, ...notEligibleReviewers].filter(
      item => !additionalInterviewerIds.has(+item.value.id),
    )
  }, [values.additional_interviewers, eligibleReviewers, notEligibleReviewers])

  const additionalInterviewersOptions = useMemo(() => {
    return [...eligibleReviewers, ...notEligibleReviewers].filter(
      item => item.value.id !== values.interviewer?.id,
    )
  }, [values.interviewer, eligibleReviewers, notEligibleReviewers])

  useEffect(() => {
    if (eligibleReviewersData?.length && !values.interviewer) {
      values.interviewer = transformOptionToEmployee(
        pickRandomValueFromArray(eligibleReviewersData),
      )
      setInterviewer(values.interviewer)
    }
  }, [eligibleReviewersData])

  const isEligibleInterviewer = (employee: EmployeeOptionInterface) =>
    eligibleReviewersData?.some(item => item.id === employee.id)

  const onChangeCustomDate = (
    newCustomDate: CustomDate,
    newTimezoneId: string = timeZoneId,
  ) => {
    values.custom_date = {
      ...newCustomDate,
      timeError: isBeforeCurrent(newCustomDate, newTimezoneId)
        ? 'The time is in the past'
        : '',
    }

    if (newCustomDate.day && newCustomDate.time) {
      const startDate = new Date(newCustomDate.day)

      const time = newCustomDate.time?.split(':')

      if (time?.length === 2) {
        startDate.setHours(+time[0])
        startDate.setMinutes(+time[1])
      }

      setCalendarEvent({
        startDate,
      })
    }
  }

  const renderInterviewerOption = (
    option: RadioSelectOption<EmployeeOptionInterface>,
  ) => (
    <>
      {option.value.name}
      <Box>
        {isEligibleInterviewer(option.value) ? (
          <Text color={Token.color.green} variant="small">
            Eligible
          </Text>
        ) : (
          <Text color={Token.color.greyTone50} variant="small">
            Not eligible
          </Text>
        )}
      </Box>
    </>
  )

  return (
    <InputGroup>
      <LapeRadioSelectInput<EmployeeOptionInterface>
        label="Lead interviewer"
        name="interviewer"
        options={leadInterviewerOptions}
        loading={eligibleReviewersLoading}
        data-testid="lead-select"
        onAfterChange={value => {
          if (value) {
            setInterviewer(value)
          }
        }}
      >
        {renderInterviewerOption}
      </LapeRadioSelectInput>
      <LapeNewMultiSelect<EmployeeOptionInterface>
        name="additional_interviewers"
        placeholder="Additional interviewers"
        options={additionalInterviewersOptions}
        variant="grey"
        onAfterChange={value => {
          if (value) {
            setAdditionalInterviewers(value.map(item => item.value))
          }
        }}
        disableSorting
      >
        {renderInterviewerOption}
      </LapeNewMultiSelect>
      {mode !== 'editing' && (
        <LapeSingleCheckbox name="is_candidate_involved" label="Invite candidate" />
      )}
      <TimezoneSelect
        onChangeTimeZone={newTimezone => {
          if (newTimezone) {
            setTimeZone(newTimezone)
          }
          if (values.custom_date) {
            onChangeCustomDate(values.custom_date, newTimezone?.id)
          }
        }}
      />
      <EditDateTime onChange={onChangeCustomDate} />
    </InputGroup>
  )
}

export default EditManualDate
