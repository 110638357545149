import '@toast-ui/calendar/dist/toastui-calendar.min.css'

import React, { useEffect, useMemo, useRef, useState } from 'react'

import { LargeWeeklyCalendarEventInterface } from '@components/LargeWeeklyCalendar/types'
import { Widget, Token, ActionButton } from '@revolut/ui-kit'
import { CandidateSchedulingInterview } from '@src/pages/Forms/Candidate/CandidateSchedulingInterview/CandidateSchedulingInterview'
import { useCandidateProfileContext } from '@src/pages/Forms/Candidate/CandidateProfileContext'
import { CandidateInterface } from '@src/interfaces/interviewTool'
import { useParams } from 'react-router-dom'
import { AvailableInterviewerSlot } from '@src/interfaces/interviewTool'
import {
  getAvailableInterviewSlots,
  useGetCustomPotentialInterviewSlots,
} from '@src/api/recruitment/interviewerScheduling'
import compact from 'lodash/compact'
import { connect } from 'lape'
import { useScheduleInterviewContext } from '@src/pages/Forms/Candidate/ScheduleInterview/ScheduleInterviewContext'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { LargeWeeklyCalendar } from '@components/LargeWeeklyCalendar'
import { utcToZonedTime } from 'date-fns-tz'
import axios, { CancelTokenSource } from 'axios'
import { addWeeks, areIntervalsOverlapping, formatISO, isSameMinute } from 'date-fns'
import { getTimeZoneId } from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'
import { getDateFromEventDate } from '@src/pages/Forms/Candidate/ScheduleInterview/utils'
import { transformEmployeeToOption } from '@src/utils/employees'
import { EmployeeOptionInterface } from '@src/interfaces/employees'

type ScheduleInterviewRootProps = {
  candidate: CandidateInterface
}

const ScheduleInterviewRoot = ({ candidate }: ScheduleInterviewRootProps) => {
  const axiosCancelToken = useRef<CancelTokenSource>()
  const { candidateId, roundId } = useParams<{ candidateId: string; roundId: string }>()
  const {
    duration,
    durationUnit,
    interviewer,
    additionalInterviewers,
    interviewStage,
    setCalendarEvent,
    calendarEvent,
    timeZone,
    disabledCalendar,
  } = useScheduleInterviewContext()
  const { setActiveAction } = useCandidateProfileContext()
  const [interviewSlots, setInterviewSlots] = useState<AvailableInterviewerSlot[]>([])
  const [weekStart, setWeekStart] = useState<Date>(new Date())
  const [showWeekends, setShowWeekends] = useState(false)

  const allInterviewers = compact([interviewer, ...(additionalInterviewers || [])])

  const { data: freeSlotsData } = useGetCustomPotentialInterviewSlots(
    interviewStage?.id,
    +roundId,
    allInterviewers,
    true,
    duration !== undefined && durationUnit?.id === 'minutes' && duration < 10
      ? 10
      : duration,
    durationUnit?.id,
  )

  const freeSlots = useMemo(() => {
    // we are filtering out free slots that are intersecting with interview slots
    return freeSlotsData?.filter(freeSlot => {
      for (let i = 0; i < interviewSlots.length; i += 1) {
        const interviewSlot = interviewSlots[i]

        if (
          areIntervalsOverlapping(
            {
              start: new Date(freeSlot.slot_start),
              end: new Date(freeSlot.slot_end),
            },
            {
              start: new Date(interviewSlot.event_start_datetime),
              end: new Date(interviewSlot.event_end_datetime),
            },
          )
        ) {
          return false
        }
      }

      return true
    })
  }, [freeSlotsData, interviewSlots])

  useEffect(() => {
    setActiveAction({
      type: 'schedule',
      mode: 'scheduling',
    })
  }, [])

  const timeZoneId = getTimeZoneId(timeZone)

  const dateRange = useMemo(() => {
    const timeZonedDate = utcToZonedTime(weekStart, timeZoneId)
    return [
      formatISO(timeZonedDate, { representation: 'date' }),
      formatISO(addWeeks(timeZonedDate, 1), {
        representation: 'date',
      }),
    ]
  }, [timeZoneId, weekStart])

  useEffect(() => {
    const fetchInterviewSlots = async (interviewers?: EmployeeOptionInterface[]) => {
      if (axiosCancelToken.current !== undefined) {
        axiosCancelToken.current.cancel('Operation canceled due to new request.')
      }
      axiosCancelToken.current = axios.CancelToken.source()

      const resp = await getAvailableInterviewSlots({
        stageId: interviewStage?.id,
        roundId: +roundId,
        dateRange,
        interviewers: interviewers?.map(transformEmployeeToOption) || [],
        duration,
        durationUnit: durationUnit?.id,
        isAdhoc: false,
        cancelToken: axiosCancelToken.current,
      })

      setInterviewSlots(resp.data.results)
    }

    if (allInterviewers.length) {
      fetchInterviewSlots(allInterviewers)
    }
  }, [
    interviewStage,
    roundId,
    dateRange,
    interviewer,
    additionalInterviewers,
    durationUnit,
    duration,
  ])

  useEffect(() => {
    if (!calendarEvent && interviewer && interviewSlots?.length) {
      setCalendarEvent({
        startDate: new Date(interviewSlots[0].event_start_datetime),
      })
    }
  }, [interviewer, interviewSlots])

  const calendarSlots = useMemo(() => {
    const calendarEventStartDate = calendarEvent?.start
    const calendarEventEndDate = calendarEvent?.end

    let s: (LargeWeeklyCalendarEventInterface & { isSelected?: boolean })[] = []

    if (freeSlots) {
      s.push(
        ...freeSlots.map(item => ({
          start: timeZone?.id
            ? utcToZonedTime(item.slot_start, timeZone?.id).toISOString()
            : item.slot_start,
          end: timeZone?.id
            ? utcToZonedTime(item.slot_end, timeZone?.id).toISOString()
            : item.slot_end,
          backgroundColor: Token.color.green_10,
          color: Token.color.black,
          isReadOnly: true,
          raw: {
            hideTime: true,
          },
        })),
      )
    }

    if (interviewSlots) {
      s.push(
        ...interviewSlots.map(item => ({
          start: item.event_start_datetime,
          end: item.event_end_datetime,
          title:
            allInterviewers.length === 1 ? 'Interview slot' : item.employee.display_name,
          color: Token.color.black,
          backgroundColor: Token.color.actionBlue,
          isReadOnly: true,
        })),
      )
    }

    if (calendarEvent && calendarEventStartDate && calendarEventEndDate) {
      s = s.filter(item => {
        const startDate = getDateFromEventDate(item.start)
        const endDate = getDateFromEventDate(item.end)

        return (
          startDate &&
          endDate &&
          !(
            isSameMinute(startDate, calendarEventStartDate) &&
            isSameMinute(endDate, calendarEventEndDate)
          )
        )
      })

      s.push({
        start: calendarEventStartDate.toISOString(),
        end: calendarEventEndDate.toISOString(),
        title: '✓ Selected',
        backgroundColor: Token.color.accent,
        color: Token.color.white,
      })
    }

    return s
  }, [freeSlots, calendarEvent, timeZone, interviewSlots, allInterviewers])

  const disabled = disabledCalendar || !interviewStage || !duration

  return (
    <>
      <CandidateSchedulingInterview
        candidate={candidate}
        refreshStats={() => {}}
        roundId={+roundId}
        alwaysOpen
        onSuccess={() => {
          navigateTo(pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id: candidateId }))
        }}
        type="calendar"
      />
      <Widget p="s-16" height="90vh" width="100%" overflow="auto">
        <LargeWeeklyCalendar
          startDayOfWeek={1}
          workweek={!showWeekends}
          eventView={['time']}
          onSwitchWeek={startDate => {
            setWeekStart(startDate)
          }}
          disabled={disabledCalendar}
          events={calendarSlots}
          onAddEvent={event => {
            const startDate = getDateFromEventDate(event.start)

            if (!startDate || !duration || disabledCalendar) {
              return
            }

            setCalendarEvent({
              startDate,
            })
          }}
          onClickEvent={({ event }) => {
            const startDate = getDateFromEventDate(event.start)

            if (disabled || !startDate) {
              return
            }

            setCalendarEvent({
              startDate,
            })
          }}
          onChangeEvent={({ changes }) => {
            const startDate = getDateFromEventDate(changes.start)

            if (disabledCalendar || !startDate) {
              return
            }

            setCalendarEvent({
              startDate,
            })
          }}
          side={
            <ActionButton
              useIcon={showWeekends ? '16/SwitchOn' : '16/SwitchOff'}
              onClick={() => setShowWeekends(prev => !prev)}
            >
              Show weekends
            </ActionButton>
          }
        />
      </Widget>
    </>
  )
}

export const ManualScheduleInterview = connect((props: ScheduleInterviewRootProps) => (
  <ScheduleInterviewRoot {...props} />
))
