import { Flex, Text, Avatar, Token, Icon } from '@revolut/ui-kit'
import { FinalGrade } from '@src/interfaces/performance'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import React from 'react'
import { css } from 'styled-components'
import { gradeSettings, pickFormattedPeriodCategory } from '../utils'

const CircleStrokeWidthCss = css`
  svg circle {
    stroke-width: 10px;
  }
`

interface Props {
  grade: FinalGrade
  periodCategory: ReviewCycleCategory | undefined
  periodName: string | undefined
  subTitle: string
}

export const GradeSection = ({ grade, periodCategory, periodName, subTitle }: Props) => (
  <Flex gap="s-16" alignItems="flex-end">
    <Avatar
      css={CircleStrokeWidthCss}
      size={76}
      progress={1}
      progressColor={
        gradeSettings?.[grade]?.title === 'N/A'
          ? Token.color.black
          : gradeSettings?.[grade]?.color
      }
      color={Token.color.foreground}
      textStyle="heading2"
    >
      {periodCategory && pickFormattedPeriodCategory(periodCategory, periodName)}
      {gradeSettings?.[grade]?.icon && (
        <Avatar.Badge
          bg={gradeSettings?.[grade]?.color}
          position="top-right"
          useIcon={
            gradeSettings?.[grade]?.icon ? (
              <Icon name={gradeSettings?.[grade]?.icon} size={20} />
            ) : null
          }
          size={24}
        />
      )}
    </Avatar>
    <Flex minWidth={110} gap="s-2" mb="s-6" flexDirection="column">
      <Text variant="heading3" whiteSpace="nowrap" color={gradeSettings?.[grade]?.color}>
        {gradeSettings?.[grade]?.title}
      </Text>
      <Text color={Token.color.greyTone50} whiteSpace="nowrap" variant="caption">
        {subTitle}
      </Text>
    </Flex>
  </Flex>
)
