import React, { useCallback } from 'react'
import ConfirmationDialog, {
  ConfirmationDialogInterface,
} from '@src/features/Popups/ConfirmationDialog'
import { KpiInterface } from '@src/interfaces/kpis'
import { useRef, useState } from 'react'
import { Box, StatusPopup, TextArea, VStack, useStatusPopup } from '@revolut/ui-kit'
import { GoalPreviewInterface, GoalUpdateType } from '@src/interfaces/goals'
import { history } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { IdAndName } from '@src/interfaces'

export const getTargets = (kpi: KpiInterface) => {
  return kpi.targets || kpi.target_epics
}

type ConfirmResultType = {
  status: 'confirmed' | 'canceled' | 'rejected'
  comment?: string
}

export const getDefaultBackRoute = (
  values: Pick<GoalPreviewInterface, 'content_type' | 'is_company'>,
  isOnboarding: boolean,
) => {
  if (isOnboarding) {
    return ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETUP.GENERAL
  }

  const directedFromPerformanceTab =
    history?.location?.state?.history?.length &&
    new RegExp(ROUTES.PERFORMANCE.GOALS.GENERAL).test(
      history.location.state.history.slice(-1),
    )

  if (directedFromPerformanceTab) {
    return ROUTES.PERFORMANCE.GOALS.GENERAL
  }
  if (values.is_company) {
    return ROUTES.FORMS.COMPANY.GOALS.GENERAL
  }

  switch (values.content_type?.model) {
    case 'employees':
      return ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.GOALS.GENERAL
    case 'teams':
      return ROUTES.FORMS.TEAM.GOALS.GENERAL
    case 'department':
      return ROUTES.FORMS.DEPARTMENT.GOALS.GENERAL
    default:
      return ROUTES.MAIN
  }
}

export const useConfirmationDialog = (
  props: Partial<ConfirmationDialogInterface> = {},
) => {
  const [popupProps, setPopupProps] = useState<
    { open: boolean } & Partial<ConfirmationDialogInterface>
  >({
    open: false,
    ...props,
  })
  const resolverRef = useRef<(result: ConfirmResultType) => void>()
  const [comment, setComment] = useState<string>()

  const onCancel = () => {
    if (resolverRef.current) {
      resolverRef.current({ status: 'rejected', comment })
      setPopupProps({ ...props, open: false })
    }
    setComment(undefined)
  }

  const onExit = () => {
    if (resolverRef.current) {
      resolverRef.current({ status: 'canceled', comment })
      setPopupProps({ ...props, open: false })
    }
    setComment(undefined)
  }

  const onConfirm = () => {
    if (resolverRef.current) {
      resolverRef.current({ status: 'confirmed', comment })
      setPopupProps({ ...props, open: false })
    }
    setComment(undefined)
  }

  const popup = (
    <ConfirmationDialog
      {...popupProps}
      onClose={onExit}
      onConfirm={onConfirm}
      onExited={onExit}
      onReject={onCancel}
    />
  )

  return {
    confirmationDialog: popup,
    confirm: (override: Partial<ConfirmationDialogInterface> = {}) => {
      return new Promise<ConfirmResultType>(resolve => {
        resolverRef.current = resolve
        setPopupProps({
          ...popupProps,
          ...override,
          open: true,
        })
      })
    },
    prompt: ({
      promptLabel,
      commentRequired,
      ...override
    }: Partial<ConfirmationDialogInterface> & {
      promptLabel?: string
      commentRequired?: boolean
    } = {}) => {
      const userBody = override.body || popupProps.body
      const body = (
        <VStack space="s-12" width="100%">
          {!!userBody && <Box>{userBody}</Box>}
          <TextArea
            label={promptLabel || (!commentRequired ? 'Optional' : undefined)}
            value={comment}
            onChange={e => {
              const userInput = e.currentTarget.value
              setComment(userInput)
              setPopupProps(current => ({
                ...current,
                submitDisabled: commentRequired && !userInput,
              }))
            }}
            rows={1}
            maxRows={3}
          />
        </VStack>
      )
      return new Promise<ConfirmResultType>(resolve => {
        resolverRef.current = resolve
        setPopupProps({
          ...popupProps,
          ...override,
          submitDisabled: commentRequired && !comment,
          open: true,
          body,
          bodyVariant: 'normal',
        })
      })
    },
  }
}

export const useSubmitFlowHelpers = ({
  confirmProps,
}: {
  confirmProps?: Partial<ConfirmationDialogInterface>
} = {}) => {
  const statusPopup = useStatusPopup()

  const showError = useCallback((title: string, description?: string) => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>{title}</StatusPopup.Title>
        {!!description && (
          <StatusPopup.Description>{description}</StatusPopup.Description>
        )}
      </StatusPopup>,
    )

    return () => statusPopup.hide()
  }, [])

  const showSuccess = useCallback(
    (title: string, description?: string, afterClose?: () => void) => {
      statusPopup.show(
        <StatusPopup variant="success" onClose={afterClose}>
          <StatusPopup.Title>{title}</StatusPopup.Title>
          {!!description && (
            <StatusPopup.Description>{description}</StatusPopup.Description>
          )}
        </StatusPopup>,
      )
      return () => statusPopup.hide()
    },
    [],
  )

  const showLoading = useCallback((title: string, description?: string) => {
    statusPopup.show(
      <StatusPopup variant="loading">
        <StatusPopup.Title>{title}</StatusPopup.Title>
        {!!description && (
          <StatusPopup.Description>{description}</StatusPopup.Description>
        )}
      </StatusPopup>,
    )
    return () => statusPopup.hide()
  }, [])

  return {
    ...useConfirmationDialog(confirmProps),
    showError,
    showSuccess,
    showLoading,
  }
}

export const isCascadedGoal = (updateType?: IdAndName<GoalUpdateType>) =>
  updateType?.id === 'cascaded'
