import React, { ReactChild, ReactNode } from 'react'
import { Location as Loc, LocationDescriptor, LocationState } from 'history'
import { selectorKeys } from '../constants/api'
import { PerformanceScores } from './scorecard'
import { CulturePercentile } from '@src/interfaces/culture'
import { OptionInterface } from '@src/interfaces/selectors'
import { RequestInterface } from '@src/interfaces/index'
import { ChangelogInterface } from '@src/interfaces/changelog'
import { CssPropType } from '@src/styles/theme'
import { useTableReturnType } from '@src/components/Table/hooks'
import { NotificationsInterface, NotificationsStatisticsInterface } from './notifications'
import { PerformanceSettingsInterface } from './settings'

export interface ChangelogApi {
  form: RequestInterface<ChangelogInterface<any>>
  field: RequestInterface<ChangelogInterface<any>>
}

export enum SubtabStatus {
  Completed,
  Pending,
}

export interface ValidatorInterface {
  validator?: object
}

export type NotificationComponentType = 'dashboard'

export interface TabComponentProps {
  type?: NotificationComponentType
  refreshStats?: (data?: object) => void
  employeeId?: number
  filterBy?: FilterByInterface[]
  tableRef?: React.MutableRefObject<useTableReturnType<
    NotificationsInterface,
    NotificationsStatisticsInterface,
    {}
  > | null>
}

export interface TabBarTableNavigationInterface {
  url?: string
  to?:
    | LocationDescriptor<LocationState>
    | ((location: Loc<LocationState>) => LocationDescriptor<LocationState>)
  path?: string | string[]
  disabled?: boolean
  component?: React.ReactNode
  preTitle?: React.ReactNode
  title: React.ReactNode
  quickSummary?: React.ReactNode
  icon?: React.ReactNode
  key?: string
}

export interface TabsInterface<T> extends TabBarTableNavigationInterface {
  path: string
  component?: any
  validator?: object
  invalidTabMessage?: (data?: T) => React.ReactNode
  visibilityCheck: (
    data?: T,
    options?: {
      performanceSettings?: PerformanceSettingsInterface
    },
  ) => boolean
}

export enum SORT_DIRECTION {
  ASC = 'asc',
  DESC = 'desc',
}

export interface FilterByInterface {
  filters: FilterOption[]
  columnName: string
  nonResettable?: boolean
  nonInheritable?: boolean
  disableQueryParam?: boolean
  filterType?: FilterType
}

export type DirectionType = SORT_DIRECTION | undefined

export interface SortByInterface {
  sortBy: string
  direction?: DirectionType
  nonInheritable?: boolean
  nonResettable?: boolean
}

export interface FilterOption {
  name: string
  id: number | string
  category?: string
}

export interface FetchDataQueryInterface {
  page?: number
  sortBy?: SortByInterface[]
  filters?: FilterByInterface[]
  selectors?: boolean
  historical?: {
    date: Date
  }
}

export enum CellTypes {
  insert = 'insert',
  text = 'text',
  link = 'link',
  date = 'date',
  dateTime = 'dateTime',
  /** To display the same date as coming from the backend, not converting to user's local timezone */
  dateInUtc = 'dateInUtc',
  longNumber = 'longNumber',
  tag = 'tag',
}

export enum FilterType {
  selector = 'selector',
  range = 'range',
  rangeWithSelector = 'rangeWithSelector',
  percentRange = 'percentRange',
  date = 'date',
  shortDate = 'shortDate',
  singleDate = 'singleDate',
  number = 'number',
  text = 'text',
  boolean = 'boolean',
}

export interface CellInsertParams<T> {
  data: T
  children: string
  dataPath: string
  idPath: string // Fixme: has to be keyof T, but requires a lot of refactoring
  parentIndexes: number[]
}

export interface ColumnCellInterface<T> {
  placeholder?: ReactNode
  dataPoint: string
  insert?: (params: CellInsertParams<T>) => ReactChild | null
  background?: (data: T) => string
  idPoint: string
  type: CellTypes
  filterType?: FilterType
  title: string
  headerTooltip?: ReactChild
  selectorsKey: selectorKeys | (() => Promise<{ options: OptionInterface[] }>)
  dynamicHyperlinks?: (data: T) => string | LocationDescriptor | null
  sortKey: string | null
  filterKey: string | null
  width: number
  colors?: (data: T) => string
  masked?: boolean
  maskTooltip?: { isShown?: ReactChild; isHidden?: ReactChild }
  noResize?: boolean
  resizeWidth?: number
  headerCentered?: boolean
  textAlign?: 'left' | 'right'
  notHoverable?: boolean
  wrapperCss?: (data: T) => CssPropType | undefined
  headerType?: 'select_all'
  padding?: string
  renderCustomHeader?: () => React.ReactNode
}

export type ColumnInterface<T> = Omit<ColumnCellInterface<T>, 'width'>

export interface RowInterface<T, S = {}> {
  cells: ColumnCellInterface<T>[]
  noChildrenRequest?: boolean | ((data: T) => boolean)
  withPeriod?: boolean
  hideAddButton?: boolean
  insertAfterTitle?: (data: T[], stats?: S) => ReactChild
  insertAfterHeader?: (
    data: T[],
    onFilterChange: (filter: FilterByInterface) => void,
    filterBy: FilterByInterface[],
    stats?: S,
  ) => ReactChild
  highlight?: (data: T) => string
  disabled?: (data: T) => boolean
  linkToForm?: (data: T, parentIndexes?: number[]) => void
  isChildrenOpener?: (data: T) => boolean
  isNotNested?: boolean
  showCycleFilter?: boolean
  isOpen?: (data: T) => boolean
}

export enum WebsocketType {
  Permission = 'permissions.changed',
  PerformanceGradesUpload = 'performance.grades.upload_result',
  BannerAdd = 'banner.add',
  BannerRemove = 'banner.remove',
  NotificationCountChanged = 'system_notifications.unread_count_changed',
  NotificationAdd = 'system_notifications.add',
}

// TODO why is this a reused interface?
export interface Stats {
  avg_done: number
  display_extend_actions: boolean
  total_deduction_to_date: number
  upcoming_changes_count: number
  document_count: number
  pending_document_count: number
  avg_performance: number
  avg_performance_label: PerformanceScores
  headcount: number
  culture_enps?: number
  culture_enps_percentile: CulturePercentile
  requisition_remaining_headcount: number
  requisition_total_headcount: number
  avg_progress: number
  department_kpi: number
  mandatory_kpi: number
  mandatory_performance: number
  mandatory_kpi_completed: number
  mandatory_kpi_incomplete: number
  pending_kpi: number
  company_kpi: number
  team_kpi: number
  refresh_date_time: string
}

export interface CompanyBudgetStats {
  consumption: number
  total_spent: number
  headcount: number
  total_budget: number
  total_estimated_spend: number
  budget_currency: string
}

export interface BudgetStats {
  headcount: number
  expense_currency: string
  total_annual_expense: number
  total_monthly_expense: number
  total_estimated_annual_expense: number
  total_estimated_monthly_expense: number
}
