import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import {
  Avatar,
  Box,
  Flex,
  Item,
  Token,
  VStack,
  Group,
  Text,
  chain,
  Icon,
  Cell,
  TabBar,
  ItemSkeleton,
} from '@revolut/ui-kit'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { BarChart, ChevronRight } from '@revolut/icons'
import {
  FinalGradeInterface,
  GradeLabelMappingInterface,
  PerformanceSelector,
  PerformanceTimelineStats,
  RequestFeedbackInterface,
  ReviewCategory,
  ReviewerRelation,
} from '@src/interfaces/performance'
import {
  getPerformanceTimelineStats,
  useGetPerformanceSelector,
} from '@src/api/performance'
import { LayoutTabProps } from '@src/pages/EmployeeProfile/Layout/common/types'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { isBefore, subDays } from 'date-fns'
import { OptionInterface } from '@src/interfaces/selectors'
import { useQuery } from '@src/utils/queryParamsHooks'
import {
  checkIsProbationPeriod,
  NewFlowRequestsResponse,
  useFetcherPerformanceRequests,
} from '@src/pages/EmployeeProfile/Preview/Performance/Common/utils'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { navigateTo } from '@src/actions/RouterActions'
import { SummaryGoalsObsoleteReview } from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SummaryGoalsObsoleteReview'
import { useGetAllFeedbackList } from '@src/api/anytimeFeedback'
import { ObsoletePerformanceReview } from '@src/pages/EmployeeProfile/Layout/Performance/ObsoletePerformanceReview'
import { ProbationSummarySection } from '@src/pages/EmployeeProfile/Layout/Performance/ProbationSummarySection'
import { PerformanceSummarySection } from '@src/pages/EmployeeProfile/Layout/Performance/PerformanceSummarySection'
import { useGetPeriodTypes } from '@src/utils/performance'
import { getPerformanceReviewSummary } from '@src/api/performanceReview'
import { getInverseColorsAndGrade } from '@components/PerformanceGrade/PerformanceGrade'
import { HeaderActions } from '@src/pages/EmployeeProfile/Layout/Performance/HeaderActions'
import {
  pickFormattedPeriodCategory,
  pickFormattedCycleName,
  gradeSettings,
} from '@src/pages/EmployeeProfile/Layout/Performance/utils'
import { PerformanceSummaryHeader } from './PerfomanceSummaryHeader'
import { useGetPromotionProgression } from '@src/api/promotions'
import { usePerformanceSummaryData } from './usePerfomanceSummaryData'
import { usePerformanceTimelineTable } from '../../Preview/Performance/PerformanceHistory/hooks'
import { useGetProbationCheckpoints } from '@src/api/probationReview'

type PerfomanceSummaryNavigationType = 'cycles' | 'analytics'

export const NewPerformanceLayoutTab = ({ data, stats }: LayoutTabProps) => {
  const { query } = useQuery(true)
  const [timelineStats, setTimelineStats] = useState<PerformanceTimelineStats | null>()
  const [selectedPeriod, setSelectedPeriod] = useState<PerformanceSelector>()
  const [initialPeriod, setInitialPeriod] = useState<PerformanceSelector>()
  const [performanceSelector, setPerformanceSelector] = useState<PerformanceSelector[]>()
  const [performanceLink, setPerformanceLink] = useState<string>()
  const [finalGrade, setFinalGrade] = useState<FinalGradeInterface>()
  const [gradesMap, setGradesMap] = useState<GradeLabelMappingInterface>()
  const [navigationTabValue, setNavigationTabValue] =
    useState<PerfomanceSummaryNavigationType>('cycles')
  const [selectedCycleId, setSelectedCycleId] = useState<number | string | undefined>()

  const { data: performanceSelectorData } = useGetPerformanceSelector(data.id)
  const user = useSelector(selectUser)
  const { data: feedback, refetch: refetchFeedbackList } = useGetAllFeedbackList(data.id)

  const isProbationPeriod = checkIsProbationPeriod(data)
  const isPIPv2 = selectedPeriod?.category === ReviewCycleCategory.PIP_V2

  const canViewAllPerformanceStats = !!data?.field_options?.permissions?.includes(
    PermissionTypes.CanViewAllPerformanceStats,
  )

  const { data: progression } = useGetPromotionProgression(data.id, selectedPeriod?.id)

  const performanceTimelineTable = usePerformanceTimelineTable(data)

  const { isNewProbation } = useGetPeriodTypes(initialPeriod)

  const { data: checkpoints } = useGetProbationCheckpoints(
    isNewProbation ? data.id : null,
    initialPeriod?.id !== undefined ? String(initialPeriod?.id) : null,
  )

  const performanceSelectorRecordById = useMemo(() => {
    return performanceSelector?.reduce<Record<string | number, PerformanceSelector>>(
      (acc, selector) => {
        acc[selector.id] = selector
        return acc
      },
      {},
    )
  }, [performanceSelector])

  const isManager =
    data.line_manager?.id === user.id || data.quality_control?.id === user.id

  const initialCategory = useMemo(() => {
    if (isProbationPeriod) {
      return ReviewCategory.Probation
    }
    if (isPIPv2) {
      return ReviewCategory.PIP_V2
    }
    return ReviewCategory.Performance
  }, [isProbationPeriod, isPIPv2])

  const category = useMemo(() => {
    return selectedPeriod?.category === ReviewCycleCategory.Probation
      ? ReviewCategory.Probation
      : selectedPeriod?.category === ReviewCycleCategory.PIP
      ? ReviewCategory.PIP_V2
      : ReviewCategory.Performance
  }, [selectedPeriod?.category])

  const perfomanceSummaryProps = usePerformanceSummaryData({
    initialPeriod,
    progression,
    checkpoints,
    timelineStats,
  })

  const fetchPerformanceSelector = () => {
    let targetPeriod
    if (performanceSelectorData) {
      const selectors = performanceSelectorData.filter(selector => {
        if (selector.category === ReviewCycleCategory.PIP) {
          return true
        }
        if (selector.category === ReviewCycleCategory.Probation) {
          // if Probation is going to start in a week or less, we want to pre-fill cycle selector with it
          const startDateAdjusted = subDays(new Date(selector.start_date_time), 7)
          return isBefore(startDateAdjusted, new Date())
        }
        return isBefore(new Date(selector.start_date_time), new Date())
      })

      const changeSelectedPeriod =
        !!selectedPeriod && !selectors.find(selector => selector.id === selectedPeriod.id)

      if (isProbationPeriod || query.cycle_id === 'null') {
        targetPeriod = selectors.find(
          selector => selector.category === ReviewCycleCategory.Probation,
        )
      } else if ((!selectedPeriod || changeSelectedPeriod) && !isProbationPeriod) {
        if (query.cycle_id) {
          targetPeriod = selectors.find(selector => {
            return `${selector.id}` === query.cycle_id
          })
        } else {
          targetPeriod = selectors.find(
            selector => selector.performance_reviews_selected_cycle,
          )
        }
      }

      setInitialPeriod(targetPeriod)
      return selectors as OptionInterface[]
    }

    return []
  }

  useEffect(() => {
    const fetchSelector = () => {
      const result = fetchPerformanceSelector()
      setPerformanceSelector(result as PerformanceSelector[])
    }
    fetchSelector()
  }, [initialCategory, performanceSelectorData])

  useEffect(() => {
    if (isManager || canViewAllPerformanceStats) {
      getPerformanceTimelineStats(data.id)
        .then(response => setTimelineStats(response.data))
        .catch(() => setTimelineStats(null))
    }
  }, [isManager, canViewAllPerformanceStats])

  let periodBeforeOngoing: PerformanceSelector | undefined
  if (selectedPeriod?.offset === 0) {
    periodBeforeOngoing = performanceSelector?.find(el => el.offset === 1)
  }

  const onPerformanceClick = () => {
    if (performanceLink) {
      navigateTo(performanceLink)
    }
  }

  const fetchFinalGrade = useCallback(async () => {
    if (selectedPeriod?.id === undefined) {
      return
    }

    const resp = await getPerformanceReviewSummary(
      String(selectedPeriod.id),
      data.id,
      category,
      [
        {
          columnName: 'reviewer_relation',
          filters: [
            {
              id: ReviewerRelation.LineManager,
              name: ReviewerRelation.LineManager,
            },
            {
              id: ReviewerRelation.FunctionalManager,
              name: ReviewerRelation.FunctionalManager,
            },
          ],
        },
      ],
    )

    const finalGradeId = resp.data?.grade_after_calibration?.display_grade
    const finalGradeLabel = finalGradeId
      ? resp.data?.grade_label_mapping[finalGradeId]
      : ''
    setFinalGrade(finalGradeId ? { id: finalGradeId, label: finalGradeLabel } : undefined)
    setGradesMap(resp.data.grade_label_mapping)
  }, [selectedPeriod?.id, category])

  const isFutureCycle = (cycleId: number | string) => {
    return performanceSelectorRecordById?.[cycleId]?.offset
      ? performanceSelectorRecordById?.[cycleId]?.offset < 0
      : false
  }

  useEffect(() => {
    const lastCycleId = performanceTimelineTable.table?.data[0]?.cycle.id
    if (lastCycleId) {
      setSelectedCycleId(lastCycleId)
      setSelectedPeriod(performanceSelectorRecordById?.[lastCycleId])
    }
  }, [performanceTimelineTable.table?.data, performanceSelectorRecordById])

  useEffect(() => {
    fetchFinalGrade()
  }, [selectedPeriod, initialCategory, selectedPeriod?.id])

  const grade = getInverseColorsAndGrade(finalGrade?.id, gradesMap)

  const { isNewFlow } = useGetPeriodTypes(selectedPeriod)

  const requestsContext = useFetcherPerformanceRequests({
    category: initialCategory,
    isNewFlow,
    id: data.id,
    performanceCycle: selectedPeriod,
  })
  const {
    data: requestsData,
    isLoading: fetchingRequests,
    refetch: refetchRequests,
  } = requestsContext

  function isNewFlowRequestsResponse(
    response: unknown,
  ): response is NewFlowRequestsResponse {
    return (
      response !== undefined &&
      response !== null &&
      typeof response === 'object' &&
      'results' in response
    )
  }

  function isRequestFeedbackInterfaceArray(
    response: unknown,
  ): response is RequestFeedbackInterface[] {
    return Array.isArray(response)
  }

  const requests = isNewFlow
    ? isNewFlowRequestsResponse(requestsData)
      ? requestsData.results
      : undefined
    : isRequestFeedbackInterfaceArray(requestsData)
    ? requestsData
    : undefined

  return (
    <VStack space="s-16" width="100%">
      <Cell>
        <VStack space="s-16" width="100%">
          <Flex flexDirection="row">
            <PerformanceSummaryHeader
              {...perfomanceSummaryProps}
              isLoading={!timelineStats}
            />
          </Flex>
          <HeaderActions
            selectedPeriod={selectedPeriod}
            data={data}
            requests={requests}
            fetchingRequests={fetchingRequests}
            checkpoints={checkpoints}
            refetchRequests={refetchRequests}
          />
        </VStack>
      </Cell>

      <TabBar value={navigationTabValue}>
        <TabBar.Item onClick={() => setNavigationTabValue('cycles')} to="cycles">
          Your review cycles
        </TabBar.Item>
        <TabBar.Item onClick={() => setNavigationTabValue('analytics')} to="analytics">
          Analytics
        </TabBar.Item>
      </TabBar>

      {navigationTabValue === 'cycles' && (
        <TwoColumnsLayout
          leftFlex={0}
          leftMinWidth={270}
          left={
            <Group>
              {performanceTimelineTable.table?.loading && (
                <>
                  <ItemSkeleton />
                  <ItemSkeleton />
                  <ItemSkeleton />
                </>
              )}
              {performanceTimelineTable.table?.data.map(item => (
                <Item
                  key={item.id}
                  use="button"
                  variant="compact"
                  aria-pressed={selectedCycleId === item.cycle.id}
                  onClick={() => {
                    const clickedPeriod = performanceSelectorData?.find(
                      selector => selector.id === item.cycle.id,
                    )
                    if (clickedPeriod) {
                      setSelectedPeriod(clickedPeriod)
                    }
                    setSelectedCycleId(item.cycle.id)
                  }}
                >
                  <Item.Avatar>
                    <Avatar
                      color={Token.color.foreground}
                      size={40}
                      textStyle="emphasis1"
                    >
                      {selectedCycleId === item.cycle.id && (
                        <Avatar.Badge
                          bg={Token.color.foreground}
                          position="bottom-right"
                          useIcon={<Icon name="Check" size={12} />}
                          size={16}
                        />
                      )}
                      {pickFormattedPeriodCategory(item.category, item.cycle?.name)}
                    </Avatar>
                  </Item.Avatar>
                  <Item.Content>
                    <Item.Title>
                      {item.employee_specialisation_seniority_sublevel?.name}
                    </Item.Title>
                    <Item.Description>
                      {pickFormattedCycleName(item.category, item.cycle?.name)}
                    </Item.Description>
                  </Item.Content>
                  <Item.Side>
                    <Item.Value>
                      {isFutureCycle(item.cycle.id) ? (
                        <Text color={Token.color.greyTone50} variant="body1">
                          Upcoming
                        </Text>
                      ) : (
                        <Text
                          color={gradeSettings[item.grade?.id]?.color}
                          variant="body1"
                        >
                          {gradeSettings[item.grade?.id]?.title}
                        </Text>
                      )}
                    </Item.Value>
                    {/* TODO will be added after changes on back-end */}
                    {/* <Item.Value>
                      <Flex alignItems="center">
                        <Icon
                          mr="s-4"
                          name="ArrowRates"
                          size={16}
                          color={Token.color.greyTone50}
                        />
                        <Text color={Token.color.greyTone50} fontSize={14}>
                          Calibrated
                        </Text>
                      </Flex>
                    </Item.Value> */}
                  </Item.Side>
                </Item>
              ))}
            </Group>
          }
          right={
            <Group>
              <Item
                use={performanceLink ? 'button' : undefined}
                onClick={onPerformanceClick}
              >
                <Item.Avatar>
                  <Avatar useIcon={BarChart} />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>Performance summary</Item.Title>
                  <Item.Description>
                    {chain(
                      selectedPeriod?.name,
                      grade ? <Text color={grade.color}>{grade.grade}</Text> : undefined,
                    )}
                  </Item.Description>
                </Item.Content>
                {performanceLink && (
                  <Item.Side>
                    <ChevronRight color={Token.color.greyTone50} />
                  </Item.Side>
                )}
              </Item>

              <Box p="s-16" pt={0}>
                <VStack space="s-16">
                  {!isNewFlow && selectedPeriod && (
                    <>
                      <SummaryGoalsObsoleteReview
                        category={selectedPeriod.category}
                        employeeId={data.id}
                        reviewCycleId={selectedPeriod.id}
                      />
                      <ObsoletePerformanceReview
                        data={data}
                        selectedPeriod={selectedPeriod}
                        feedback={feedback}
                        refetchFeedbackList={refetchFeedbackList}
                      />
                    </>
                  )}
                  {selectedPeriod && (
                    <>
                      {selectedPeriod.category === ReviewCycleCategory.Performance ? (
                        <PerformanceSummarySection
                          data={data}
                          stats={stats}
                          selectedPeriod={selectedPeriod}
                          periodBeforeOngoing={periodBeforeOngoing}
                          setPerformanceLink={setPerformanceLink}
                          feedback={feedback}
                          refetchFeedbackList={refetchFeedbackList}
                        />
                      ) : (
                        <ProbationSummarySection
                          data={data}
                          selectedPeriod={selectedPeriod}
                          setPerformanceLink={setPerformanceLink}
                          feedback={feedback}
                          refetchFeedbackList={refetchFeedbackList}
                        />
                      )}
                    </>
                  )}
                </VStack>
              </Box>
            </Group>
          }
        />
      )}
    </VStack>
  )
}
