import { GetRequestData, IdAndName } from '@src/interfaces'
import {
  DriverResultStatsInterface,
  EngagementSurveyHistoryInterface,
} from '@src/interfaces/engagement'
import { formatPercentage } from '@src/utils/format'

interface Params {
  rounds?: GetRequestData<EngagementSurveyHistoryInterface>
  selectedRound?: IdAndName
  stats?: DriverResultStatsInterface
}

export const calculateAudience = ({ rounds, selectedRound, stats }: Params) => {
  const roundData = rounds?.results.find(result => result.id === selectedRound?.id)
  if (roundData) {
    return {
      audienceSize: roundData.audience_size,
      participationRate: formatPercentage(
        roundData.audience_size
          ? (roundData.response_count || 0) / roundData.audience_size
          : 0,
        2,
      ),
    }
  }
  return {
    audienceSize: stats?.total_headcount,
    participationRate: formatPercentage(stats?.participation_rate || 0, 2),
  }
}
