import React, { useEffect } from 'react'
import { ScoreType, ViewType } from '../types'
import { IdAndName } from '@src/interfaces'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  engagementDriverActionsColumn,
  engagementResultsAverageDistributionColumn,
  engagementResultsAverageScoreColumn,
  engagementResultsCategoryNameColumn,
  engagementResultsNpsDistributionColumn,
  engagementResultsNpsScoreColumn,
  engagementResultsTrendAverageScoreColumn,
  engagementResultsTrendNpsScoreColumn,
} from '@src/constants/columns/engagementResults'
import { Box, Token } from '@revolut/ui-kit'
import { useTable } from '@src/components/Table/hooks'
import {
  EngagementResultInterface,
  EngagementResultsEntities,
  EngagementResultsScope,
} from '@src/interfaces/engagement'
import {
  getEngagementResultsTableRequests,
  getSurveyAdminResultDriversTableRequests,
} from '@src/api/engagement'
import { useCommentsSidebar } from './CommentsActionSidebar'

interface Props {
  view: ViewType
  scoreType: ScoreType
  selectedRound: IdAndName
  surveyId: number
  entity?: { type: EngagementResultsScope; id: number }
}

const trendColumnTooltip = (
  <Box color={Token.color.background} p="s-8" minWidth={250}>
    This indicates how much the score for this category has increased or decreased since
    the last time it was asked to employees
  </Box>
)

const getRows = (
  setQuestionSidebar: (
    driver: EngagementResultInterface['driver'],
    question: EngagementResultInterface['question'],
  ) => void,
) => ({
  cells: [
    { ...engagementResultsCategoryNameColumn, width: 250 },
    { ...engagementResultsAverageScoreColumn, width: 105 },
    {
      ...engagementResultsNpsScoreColumn,
      width: 105,
    },
    { ...engagementResultsAverageDistributionColumn, width: 300 },
    {
      ...engagementResultsNpsDistributionColumn,
      width: 300,
    },
    {
      ...engagementResultsTrendAverageScoreColumn,
      width: 65,
      headerTooltip: trendColumnTooltip,
    },
    {
      ...engagementResultsTrendNpsScoreColumn,
      width: 65,
      headerTooltip: trendColumnTooltip,
    },
    { ...engagementDriverActionsColumn(setQuestionSidebar), width: 40 },
  ],
})

export const ResultCategories = ({
  view,
  scoreType,
  selectedRound,
  surveyId,
  entity,
}: Props) => {
  const { setSidebar, commentsSidebar } = useCommentsSidebar(surveyId)
  const table = useTable<EngagementResultInterface>(
    entity
      ? getEngagementResultsTableRequests(
          entity.id,
          surveyId,
          entity.type,
          EngagementResultsEntities.Drivers,
        )
      : getSurveyAdminResultDriversTableRequests({ surveyId }),
    [
      {
        columnName: 'survey_round_id',
        filters: [{ id: selectedRound.id, name: String(selectedRound.id) }],
        nonInheritable: true,
        nonResettable: true,
      },
    ],
  )

  useEffect(() => {
    const currentSurveyRound = table.filterBy
      .find(({ columnName }) => columnName === 'survey_round_id')
      ?.filters.at(0)?.id
    if (selectedRound.id !== currentSurveyRound) {
      table.onFilterChange({
        columnName: 'survey_round_id',
        filters: [{ id: selectedRound.id, name: String(selectedRound.id) }],
        nonInheritable: true,
        nonResettable: true,
      })
    }
  }, [selectedRound])

  const hiddenCells = {
    [engagementResultsAverageScoreColumn.idPoint]: scoreType === 'engagement',
    [engagementResultsAverageDistributionColumn.idPoint]: scoreType === 'engagement',
    [engagementResultsTrendAverageScoreColumn.idPoint]: scoreType === 'engagement',
    [engagementResultsNpsScoreColumn.idPoint]: scoreType === 'average',
    [engagementResultsNpsDistributionColumn.idPoint]: scoreType === 'average',
    [engagementResultsTrendNpsScoreColumn.idPoint]: scoreType === 'average',
  }

  return view === 'table' ? (
    <>
      <AdjustableTable
        name={TableNames.EngagementSurveysResultCategories}
        row={getRows((driver, question) => setSidebar({ driver, question }))}
        rowHeight="large"
        hiddenCells={hiddenCells}
        {...table}
      />
      {commentsSidebar}
    </>
  ) : null
}
