import React from 'react'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { FormattedMessage } from 'react-intl'
import { AddGoalAction } from './AddGoalAction'

export const EmptyGoalTable = () => {
  return (
    <EmptyTableRaw
      title={
        <FormattedMessage
          defaultMessage="Goals go here"
          id="performance.goals.emptyTable.title"
        />
      }
      action={
        <AddGoalAction
          variant="text"
          label={
            <FormattedMessage
              id="performance.goals.emptyTable.addNewAction"
              defaultMessage="Create your first goal"
            />
          }
        />
      }
    />
  )
}
