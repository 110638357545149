import React, { useMemo, useState } from 'react'
import { TopNav, Box, Token, Icon, useMatchMedia } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'

import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { SideBarEvent } from '@src/features/MainLayout/SideBar/SideBarEvent'
import Notifications from '@src/features/Notifications/Notifications'
import { GlobalSearch } from '@src/features/MainHeader/GlobalSearch'
import { ROUTES } from '@src/constants/routes'
import { UserProfile } from '@src/features/TopNav/UserProfile'
import { useBanner } from '@src/features/UIKitWithThemeProvider/BannerProvider'
import { useCoreNavigation } from '@src/features/MainLayout/useCoreNavigation'
import { selectAuthenticated } from '@src/store/auth/selectors'

const PositionOffsetWrapper: React.FC<{ noOffset?: boolean }> = ({
  noOffset,
  children,
}) => {
  const { visible } = useBanner()

  if (!visible || noOffset) {
    return <>{children}</>
  }

  return <Box style={{ position: 'relative', top: 28 }}>{children}</Box>
}

export const useTopNav = () => {
  const [enabled, setEnabled] = useState(false)

  const mdMedia = useMatchMedia(Token.media.md)
  const authenticated = useSelector(selectAuthenticated)
  const isCoreNavigation = useCoreNavigation()

  const topNav = useMemo(() => {
    if (enabled && isCoreNavigation && authenticated) {
      return {
        title: 'People',
        logo: (
          <PositionOffsetWrapper noOffset={mdMedia}>
            <TopNav.Logo
              useIcon={
                <Box style={{ position: 'relative' }}>
                  <Icon name="LogoRevolut" color={Token.color.white} />
                  <SideBarEvent />
                </Box>
              }
              to={ROUTES.MAIN}
              use={InternalLink}
              bg={Token.color.blue}
              color={Token.color.white}
            />
          </PositionOffsetWrapper>
        ),
        profile: (
          <PositionOffsetWrapper>
            <UserProfile />
          </PositionOffsetWrapper>
        ),
        // TODO: https://revolut.atlassian.net/browse/REVCOR-2701 use backoffice-ui-provider app switcher for internal. For external it's not clear which apps will be shown, so hide it for now
        appSwitcher: (
          <PositionOffsetWrapper>
            <></>
          </PositionOffsetWrapper>
        ),
        globalSearch: (
          <PositionOffsetWrapper>
            <GlobalSearch />
          </PositionOffsetWrapper>
        ),
        notifications: (
          <PositionOffsetWrapper>
            <Notifications />
          </PositionOffsetWrapper>
        ),
      }
    }

    return undefined
  }, [enabled, isCoreNavigation, authenticated, mdMedia])

  return {
    topNav,
    setEnabled,
  }
}
