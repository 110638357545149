import React from 'react'
import { Box, Group, HStack, Link, Widget } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import { PageBody } from '@components/Page/PageBody'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { goBack, navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { CommunicationDetails } from '@src/features/CommunicationGroups/CommunicationDetails'
import { FunctionInterface } from '@src/interfaces/functions'
import SettingsButtons, { CopyButton } from '@src/features/SettingsButtons'
import PermissionTransferButton from '@src/pages/PermissionTransfer/Button/PermissionTransferFormButton'
import { PermissionTypes } from '@src/store/auth/types'
import { functionsRequestsNew, silentDeleteFunction } from '@src/api/functions'
import { CommunicationActions } from '@src/features/CommunicationGroups/CommunicationActions'
import Stat from '@src/components/Stat/Stat'
import { formatPercentage } from '@src/utils/format'
import { getPercentColor } from '@src/components/ColumnInserts/ColoredPercent/ColoredPercent'
import { useGetOrganisationSettings } from '@src/api/settings'
import LapeDeleteOrgUnitButton from '@src/features/SettingsButtons/DeleteOrgUnitButton/LapeDeleteOrgUnitButton'
import { ArchiveFunctionButton } from '@src/pages/Forms/FunctionsForm/Summary/ArchiveFunctionButton'

type Props = {
  data?: FunctionInterface
}
export const Summary = ({ data }: Props) => {
  const { data: settings } = useGetOrganisationSettings()
  const showCostControlAssignment = !!settings?.enable_cost_control_assignment
  return (
    <Form api={functionsRequestsNew}>
      <PageBody>
        <Widget p="s-16">
          <HStack space="s-24">
            <Stat label="Headcount" val={data?.headcount} />
            <Stat label="Requisitions" val={data?.requisition_headcount} />
            <Stat
              label="NIPS"
              val={formatPercentage(data?.nips ?? null)}
              color={getPercentColor(data?.nips && data?.nips * 100)}
            />
          </HStack>
          {!!data?.id && (
            <SettingsButtons mt="s-16">
              <CommunicationActions group={data.communication_group} />
              <PermissionTransferButton
                path={ROUTES.FORMS.PERMISSION_MANAGEMENT.ENTITIES.FUNCTION}
              />
              <CopyButton
                afterSubmitUrl={pathToUrl(ROUTES.FORMS.FUNCTION.SETTINGS, {})}
              />
              <LapeDeleteOrgUnitButton
                onAfterDelete={() => goBack(ROUTES.ORGANISATION.ROLES.FUNCTIONS)}
                deleteApi={silentDeleteFunction}
                prefix="function"
                displayName="function"
              />
              <ArchiveFunctionButton data={data} />
            </SettingsButtons>
          )}
        </Widget>
        <Box mt="s-16">
          <FormPreview<FunctionInterface>
            data={data}
            title="Details"
            onEdit={() =>
              navigateTo(pathToUrl(ROUTES.FORMS.FUNCTION.SETTINGS, { id: data?.id }))
            }
            hideEdit={d =>
              !d?.field_options?.permissions?.includes(PermissionTypes.ChangeFunction)
            }
          >
            <Group>
              <FormPreview.Item<FunctionInterface> field="name" title="Department name" />
              <FormPreview.Item<FunctionInterface>
                field="owner"
                title="Owner"
                type="employee"
              />
              <FormPreview.Item<FunctionInterface>
                field="delegated_requisition_approver"
                title="Requisition approver"
                type="employee"
              />
              {showCostControlAssignment && (
                <FormPreview.Item<FunctionInterface>
                  field="cost_control"
                  title="Cost control"
                  type="employee"
                />
              )}
              <FormPreview.Item<FunctionInterface>
                field="documentation_link"
                title="Link to playbooks"
                insert={({ documentation_link }) =>
                  documentation_link ? (
                    <Link href={documentation_link} target="_blank">
                      Open
                    </Link>
                  ) : (
                    '-'
                  )
                }
              />
              <FormPreview.Item<FunctionInterface> field="mission" title="Mission" />
              <CommunicationDetails
                group={data?.communication_group}
                linkedGroups={data?.linked_communication_groups}
              />
            </Group>
          </FormPreview>
        </Box>
      </PageBody>
    </Form>
  )
}
