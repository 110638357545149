import {
  ActionButton,
  Button,
  InputGroup,
  Popup,
  VStack,
  Text,
  Token,
  StatusWidget,
  Flex,
  Side,
  useToggle,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalDashboardDetails, GoalsInterface } from '@src/interfaces/goals'
import React, { useEffect, useState } from 'react'
import { CellWithItem } from '../common/CellWithItem'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import { AnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { TargetsWidget } from './Widgets/Targets/TargetsWidgetNew'
import { RoadmapsWidget } from './Widgets/Roadmaps/RoadmapsWidget'
import { DashboardsWidget } from './Widgets/Dashboards/DashboardsWidget'
import { OrgUnitSelector } from './OrgUnitSelector'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { ParentGoalField } from '../common/ParentGoalField'
import { KpiInterface } from '@src/interfaces/kpis'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { Id } from '@src/interfaces'
import { LinkRoadmapsForm } from './SidebarForms/LinkRoadmapsForm'
import {
  RoadmapInterfaceForm,
  UnassignedRoadmapInterface,
} from '@src/interfaces/roadmaps'
import {
  addGoalRoadmap,
  normalizeRoadmapInterface,
  roadmapsRequestsNew,
} from '@src/api/roadmaps'
import { captureException } from '@sentry/core'
import { useSubmitFlowHelpers } from '../common/utils'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  createRelatedCompany,
  createRelatedDepartments,
  createRelatedEmployees,
  createRelatedTeams,
  getAllLookerDashboards,
  updateDashboard,
} from '@src/api/analyticsDashboards'
import SideBar from '@src/components/SideBar/SideBar'
import { useGoalRoadmapsTable } from '@src/features/Goals/common/useGoalRoadmapsTable'
import RadioSelectInput, {
  createNewKey,
} from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import styled from 'styled-components'
import { useGetRoadmapSettings } from '@src/api/settings'
import { RoadmapForm } from '@src/pages/Forms/RoadmapForm/RoadmapForm'
import Form from '@src/features/Form/Form'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { isGenericNewGoalPath } from '../helpers'
import { useQuery } from '@src/utils/queryParamsHooks'
import { StrategicGoalField } from '../common/StrategicGoalField'
import { StrategicGoalPopup } from './StrategicGoalPopup'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { isString } from 'lodash'

type RightSideContent =
  | {
      type: 'target'
      item: Partial<KpiInterface>
    }
  | {
      type: 'roadmap'
      item?: Partial<RoadmapInterfaceForm>
    }
  | {
      type: 'dashboard'
      item?: GoalDashboardDetails
    }
  | null

export const GoalForm = ({
  enforceParentKpi,
  defaultReviewCycle,
}: {
  enforceParentKpi: boolean
  defaultReviewCycle?: ReviewCyclesInterface
}) => {
  const { values, apiErrors } = useLapeContext<GoalsInterface>()
  const { query } = useQuery<{ type: 'department' | 'teams' | 'companyV2' }>()
  const isGenericNewGoal = isGenericNewGoalPath()
  const isCommercial = useIsCommercial()
  const [isStrategicGoalCreateVisible, toggleStrategicGoalCreateVisible] = useToggle()

  const roadmapsTable = useGoalRoadmapsTable()

  const contentType =
    isGenericNewGoal && ['department', 'teams'].includes(query.type)
      ? (query.type as 'department' | 'teams')
      : values.content_type?.model

  const isCompany = isGenericNewGoal && query.type === 'companyV2'

  useEffect(() => {
    // this is the simplest way to refetch roadmaps when target removed
    roadmapsTable.refresh()
  }, [values.kpis.length])

  const [rightSide, setRightSide] = useState<RightSideContent>(null)

  return (
    <VStack space="s-24">
      <CellWithItem
        icon="Target"
        title="Define your goal"
        description="What is the outcome you want to achieve?"
      >
        <InputGroup data-testid="goal-form-general">
          <LapeNewInput
            name="name"
            label="Goal name"
            required
            hasError={!!apiErrors.name}
            errorMessage={apiErrors.name}
          />
          <LapeRadioSelectInput
            selector={selectorKeys.employee}
            name="owner"
            label="Owner"
            required
          />
          <OrgUnitSelector />
          {isCompany ? (
            <StrategicGoalField
              error={apiErrors.parent}
              required
              value={values.parent}
              onChange={parent => {
                if (parent && isString(parent.id) && parent.id === createNewKey) {
                  toggleStrategicGoalCreateVisible(true)
                } else if (parent) {
                  values.parent = parent
                }
              }}
            />
          ) : (
            <ParentGoalField
              error={apiErrors.parent}
              contentType={contentType}
              required={enforceParentKpi}
              value={values.parent}
              onChange={parent => {
                values.parent = parent
              }}
            />
          )}
          <LapeNewTextArea name="description" label="Description" required rows={1} />
        </InputGroup>
      </CellWithItem>
      <TargetsWidget reviewCycle={defaultReviewCycle} />
      <RoadmapsWidget
        onAdded={() => setRightSide({ type: 'roadmap' })}
        onSelected={roadmap =>
          setRightSide({ type: 'roadmap', item: normalizeRoadmapInterface(roadmap) })
        }
      />
      <DashboardsWidget
        onAdd={() => setRightSide({ type: 'dashboard' })}
        onSelected={dash => setRightSide({ type: 'dashboard', item: dash })}
      />

      {rightSide?.type === 'roadmap' ? (
        <AddRoadmapsSide
          reviewCycle={defaultReviewCycle}
          onAfterAdded={() => {
            setRightSide(null)
            roadmapsTable.refresh()
          }}
          onClose={() => setRightSide(null)}
          roadmapId={rightSide.item?.id}
        />
      ) : rightSide?.type === 'dashboard' ? (
        <AddDashboardSide
          dashboard={rightSide.item}
          onAfterAdded={(inputData: AnalyticsDashboardInterface) => {
            if (rightSide.item) {
              values.dashboards = values.dashboards.map(d =>
                d.id !== rightSide.item?.id ? d : inputData,
              )
            } else {
              values.dashboards = [...values.dashboards, inputData]
            }

            setRightSide(null)
          }}
          onClose={() => setRightSide(null)}
        />
      ) : null}
      {!isCommercial && (
        <StrategicGoalPopup
          open={isStrategicGoalCreateVisible}
          onClose={() => toggleStrategicGoalCreateVisible(false)}
        />
      )}
    </VStack>
  )
}

function AddRoadmapsSide({
  reviewCycle,
  onAfterAdded,
  onClose,
  roadmapId,
}: {
  reviewCycle?: ReviewCyclesInterface
  onAfterAdded: () => void
  onClose: () => void
  roadmapId?: number
}) {
  const { data: settings } = useGetRoadmapSettings()
  const { values } = useLapeContext<GoalsInterface>()
  const { showError } = useSubmitFlowHelpers()
  const [pendingSubmit, setPendingSubmit] = useState(false)
  const { query } = useQuery()

  const jiraEnabled = settings?.jira_epics_enabled

  const getEntityKey = (): 'employee' | 'team' | 'department' | undefined => {
    if (values.content_type) {
      return values.content_type.model === 'employees'
        ? 'employee'
        : values.content_type.model === 'teams'
        ? 'team'
        : 'department'
    }
    if (query.type) {
      switch (query.type) {
        case 'department':
          return 'department'

        case 'teams':
          return 'team'
        default:
          return undefined
      }
    }
    return undefined
  }
  const entityKey = getEntityKey()

  const submitJira = async (
    cycle: ReviewCyclesInterface,
    epics: UnassignedRoadmapInterface[],
  ): Promise<void> => {
    try {
      setPendingSubmit(true)

      if (values.is_company || values.is_top_level) {
        await addGoalRoadmap({
          keys: epics.map(epic => epic.key),
          review_cycle: cycle,
          goal: { id: values.id },
          is_company: true,
        })
      } else {
        await addGoalRoadmap({
          keys: epics.map(epic => epic.key),
          review_cycle: cycle,
          goal: { id: values.id },
          ...(entityKey && { [entityKey]: { id: values.object_id } }),
        })
      }
    } catch (err) {
      captureException(err)
      showError('Failed to add roadmap', 'Please, try again.')
    } finally {
      setPendingSubmit(false)
    }
  }

  return (
    <SideBar
      useLayout
      onClose={onClose}
      title={roadmapId ? 'Edit roadmap' : 'Add roadmap'}
      data-testid="roadmaps-form"
    >
      {jiraEnabled ? (
        <LinkRoadmapsForm
          pending={pendingSubmit}
          initialCycle={reviewCycle}
          ownerId={values.owner.id}
          submit={async ({ cycle, epics }) => {
            await submitJira(cycle, epics)
            onAfterAdded()
          }}
          exclude={values.roadmaps}
        />
      ) : (
        <Form
          forceParams={roadmapId ? { id: String(roadmapId) } : undefined}
          api={roadmapsRequestsNew}
          initialValues={
            roadmapId
              ? undefined
              : {
                  is_company: values.is_company || values.is_top_level,
                  ...(entityKey && { [entityKey]: { id: values.object_id } }),
                  goal: { id: values.id },
                  owner: values.owner,
                  review_cycle: reviewCycle,
                }
          }
          disableLocalStorageCaching
          useLocalStorageCaching={false}
        >
          <RoadmapForm disabledFields={{ owner: 'owner is prefilled' }} />
          <Side.Actions>
            <NewSaveButtonWithPopup onAfterSubmit={onAfterAdded} useValidator />
          </Side.Actions>
        </Form>
      )}
    </SideBar>
  )
}

function AddDashboardSide({
  onAfterAdded,
  dashboard,
  onClose,
}: {
  onAfterAdded: (dashboard: AnalyticsDashboardInterface) => void
  dashboard?: GoalDashboardDetails
  onClose: () => void
}) {
  const { values } = useLapeContext<GoalsInterface>()
  const { showError, showSuccess } = useSubmitFlowHelpers()
  const [pendingSubmit, setPendingSubmit] = useState(false)
  const { navigateWithEntity, entity } = useOrgEntity()
  const [selected, setSelected] = useState<AnalyticsDashboardInterface | undefined>(
    dashboard as AnalyticsDashboardInterface | undefined,
  )

  const submit = async (dash: AnalyticsDashboardInterface): Promise<void> => {
    const { id, related_goals } = dash
    const payload: Id & Partial<AnalyticsDashboardInterface> = {
      id,
      related_goals: [...(related_goals || []), { id: values.id }],
    }

    const afterSubmit = (result: Id) => {
      try {
        switch (entity?.type) {
          case EntityTypes.employee:
          case EntityTypes.employees:
            createRelatedEmployees(result.id, entity.data.id)
            break

          case EntityTypes.team:
          case EntityTypes.teams:
            createRelatedTeams(result.id, entity.data.id)
            break

          case EntityTypes.department:
            createRelatedDepartments(result.id, entity.data.id)
            break

          case EntityTypes.company:
            createRelatedCompany(result.id)
            break
        }
      } catch (err) {
        captureException(err)
      }
    }

    try {
      setPendingSubmit(true)
      if (dashboard) {
        await updateDashboard({
          id: dashboard.id,
          related_goals: dashboard.related_goals?.filter(g => g.id !== values.id),
        })
      }
      await updateDashboard(payload)
      showSuccess('Dashboard added.')
      afterSubmit(payload)
      onAfterAdded(dash)
    } catch (err) {
      captureException(err)
      showError('Failed to link dashboard', 'Please, try again.')
    } finally {
      setPendingSubmit(false)
    }
  }

  const dashboardFormUrl = pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_DASHBOARD.DETAILS, {})
  const isNotIncluded = ({ id }: Id) => {
    return !values.dashboards.find(d => d.id === id)
  }

  const popupActionsHeight = '100px'

  return (
    <Popup
      size="lg"
      onClose={onClose}
      data-testid="dashboard-form"
      open
      shouldKeepMaxHeight
    >
      <VStack gap="s-16" height={`calc(100% - ${popupActionsHeight})`}>
        {dashboard ? null : (
          <ActionButton
            useIcon="ArrowThinRight"
            onClick={() =>
              navigateWithEntity(dashboardFormUrl, {
                goalId: values.id,
                entityType: entity?.type,
                entityId: entity?.data.id,
                entityName: entity?.data.name,
              })
            }
          >
            Create new dashboard
          </ActionButton>
        )}
        <RadioSelectInput<AnalyticsDashboardInterface>
          label="Select dashboard"
          value={selected}
          onChange={d => setSelected(d || undefined)}
          selector={() =>
            getAllLookerDashboards().then(response =>
              response.data.results.filter(isNotIncluded),
            )
          }
        >
          {option => (
            <VStack m="-s-2">
              <Text variant="primary">{option.label}</Text>
              <Text variant="caption" color={Token.color.greyTone50}>
                {option.value.description}
              </Text>
            </VStack>
          )}
        </RadioSelectInput>
        <DashboardPreview dashboard={selected} />
      </VStack>
      <Popup.Actions>
        <Button
          pending={pendingSubmit}
          disabled={!selected || (dashboard && selected.id === dashboard.id)}
          onClick={() => submit(selected!)}
        >
          {dashboard ? 'Change dashboard' : 'Add dashboard'}
        </Button>
      </Popup.Actions>
    </Popup>
  )
}

const StretchedStatusWidget = styled(StatusWidget)`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`

export function DashboardPreview({
  dashboard,
  minHeight = 'auto',
}: {
  dashboard?: GoalDashboardDetails
  minHeight?: number | 'auto'
}) {
  return (
    <Flex
      border={`1px solid ${Token.color.greyTone20}`}
      borderRadius={Token.radius.r16}
      height="100%"
      flex={1}
      alignItems="center"
      flexDirection="column"
      width="100%"
    >
      {dashboard && dashboard.embed_url ? (
        <iframe
          data-testid="looker-iframe"
          height="100%"
          src={dashboard.embed_url}
          style={{
            border: 0,
            borderRadius: '16px',
            minHeight,
          }}
          title="Dashboard"
          width="100%"
        />
      ) : !dashboard ? (
        <StretchedStatusWidget>
          <StatusWidget.Description>
            Select an internal dashboard
          </StatusWidget.Description>
        </StretchedStatusWidget>
      ) : (
        <StretchedStatusWidget>
          <StatusWidget.Description>Preview is not available</StatusWidget.Description>
        </StretchedStatusWidget>
      )}
    </Flex>
  )
}
